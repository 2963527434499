<template>
  <el-dialog
    :model-value="dialogVisible"
    title="请输入登录密码"
    width="300px"
    @close="handleClose"
    style="border-radius: 10px"
    draggable
  >
    <el-input v-model="passWord" show-password />
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { ElMessage } from 'element-plus'
import md5 from 'blueimp-md5'
import { ValidatePass } from '@/api/User'
const passWord = ref('')
const dialogVisible = ref(false)

const emits = defineEmits(['update:modelValue', 'SetValue'])
const handleClose = () => {
  emits('update:modelValue', false)
}
const handleConfirm = () => {
  ValidatePass({ passWord: md5(passWord.value) })
    .then((res) => {
      ElMessage({
        message: '密码验证成功',
        type: 'success'
      })
      handleClose()
      emits('SetValue')
    })
    .catch((err) => {
      ElMessage({
        message: '密码验证失败',
        type: 'error'
      })
      handleClose()
    })
}
</script>
