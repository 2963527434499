<template>
  <div class="content">
    <TitleInfo title1="设备管理" title2="远程控制"></TitleInfo>
    <div class="main">
      <div class="title flexCB">
        <div class="key align-center">
          <div>设备类型：</div>
          <el-select
            v-model="deviceTypeID"
            placeholder="Select"
            @change="HandleChangeDeviceType"
          >
            <el-option
              v-for="item in typeInfo"
              :key="item.ID"
              :label="item.Name"
              :value="item.ID"
            />
          </el-select>
          <div style="margin-left: 10px">设备名称：</div>
          <el-select v-model="queryForm.deviceID" placeholder="Select">
            <el-option
              v-for="item in deviceInfo"
              :key="item.ID"
              :label="item.Name"
              :value="item.ID"
            />
          </el-select>
          <div style="margin-left: 10px">参数名称：</div>
          <el-input
            v-model="queryForm.parameName"
            placeholder="请输入参数名称"
            class="keyInput"
          />
          <div style="margin-left: 10px">位号名称：</div>
          <el-input
            v-model="queryForm.dcsName"
            placeholder="请输入位号名称"
            class="keyInput"
          />
          <el-button
            style="margin-left: 10px"
            :icon="Search"
            color="#11488B"
            :round="false"
            @click="initGetList"
            >搜索</el-button
          >
          <el-button
            :icon="Refresh"
            color="#67C23A"
            :round="false"
            @click="resetInfo"
            >重置</el-button
          >
        </div>
      </div>
      <div class="tableInfo">
        <el-table
          :data="tableData"
          border
          class="tableInfo"
          style="width: 100%; height: 100%"
        >
          <el-table-column type="index" width="50"> </el-table-column>
          <el-table-column
            prop="DeviceTypeName"
            label="设备类型"
          ></el-table-column>
          <el-table-column prop="CustomName" label="客户名称"></el-table-column>
          <el-table-column prop="DeviceName" label="设备名称"></el-table-column>
          <el-table-column prop="ParameName" label="参数名称"></el-table-column>
          <el-table-column prop="DCSName" label="位号名称"></el-table-column>
          <el-table-column
            prop="Value"
            label="当前值"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="CreateTime"
            label="采集时间"
            width="200"
          ></el-table-column>
          <el-table-column prop="Remarks" label="描述"></el-table-column>
          <el-table-column
            prop="action"
            label="操作"
            width="200"
            v-if="user.CanEdit"
          >
            <template #default="{ row }">
              <el-button
                v-if="user.CanEdit"
                size="small"
                type="warning"
                :icon="Edit"
                @click="handleDialogValue(row)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="bottom align-center">
        <el-pagination
          v-model:current-page="queryForm.pageIndex"
          v-model:page-size="queryForm.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :background="true"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          popper-class="dropdownPopper"
        />
      </div>
    </div>
    <InputPassDialog
      v-model="inputPassDialogVisible"
      v-if="inputPassDialogVisible"
      @SetValue="SetValue"
    ></InputPassDialog>
    <InputNewValueDialog
      v-model="inputNewValueDialogVisible"
      v-if="inputNewValueDialogVisible"
      :deviceID="queryForm.deviceID"
      :parameName="selectParameName"
    ></InputNewValueDialog>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue'
import { Search, Refresh, Edit, Delete } from '@element-plus/icons-vue'
import { isNull } from '@/utitls/validate'
import { ElMessage, ElMessageBox } from 'element-plus'
import TitleInfo from '@/components/TitleInfo'
import { GetRealTimeDataPageList } from '@/api/DataRecord'
import { GetDeviceTypeFillList } from '@/api/DeviceType'
import { GetDeviceFillListByTypeID } from '@/api/Device'
import InputPassDialog from './components/inputPassDialog.vue'
import InputNewValueDialog from './components/inputNewValueDialog.vue'
import { useStore } from 'vuex'
const queryForm = ref({
  deviceID: '',
  parameName: '',
  dcsName: '',
  pageIndex: 1,
  pageSize: 10,
  GetALL: false,
  ReadType: '读写'
})
const deviceTypeID = ref('')
const typeInfo = ref([])
const deviceInfo = ref([])
const total = ref(0)
const tableData = reactive([])
const inputPassDialogVisible = ref(false)
const inputNewValueDialogVisible = ref(false)
const user = ref({})
const selectParameName = ref('')
onMounted(() => {
  user.value = useStore().state.login.user
  GetDeviceTypeFillList()
    .then((res) => {
      if (res) {
        if (res.Data.length > 0) {
          res.Data.forEach((item) => {
            typeInfo.value.push({
              ID: item.ID,
              Name: item.Name
            })
          })
          deviceTypeID.value = res.Data[0].ID
          initDeviceList()
        }
      }
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `${err}`,
        type: 'error'
      })
    })
})
const initDeviceList = () => {
  deviceInfo.value.length = 0
  GetDeviceFillListByTypeID({ deviceTypeID: deviceTypeID.value })
    .then((res) => {
      if (res) {
        if (res.Data.length > 0) {
          res.Data.forEach((item) => {
            deviceInfo.value.push({
              ID: item.ID,
              Name: item.Name
            })
          })
          queryForm.value.deviceID = res.Data[0].ID
          initGetList()
        }
      }
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `${err}`,
        type: 'error'
      })
    })
}
const initGetList = async () => {
  GetRealTimeDataPageList(queryForm.value)
    .then((res) => {
      if (res) {
        total.value = res.Total
        tableData.length = 0
        if (res.Total > 0)
          res.Data.forEach((element) => {
            tableData.push(element)
          })
      } else {
        total.value = 0
        tableData.length = 0
      }
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `${err}`,
        type: 'error'
      })
      console.log(err)
      total.value = 0
      tableData.length = 0
    })
}
const handleSizeChange = (pageSize) => {
  queryForm.value.pageIndex = 1
  initGetList()
}
const handleCurrentChange = (pageNum) => {
  initGetList()
}
const resetInfo = () => {
  queryForm.value = {}
  initGetList()
}
const HandleChangeDeviceType = (e) => {
  queryForm.value.deviceID = ''
  total.value = 0
  tableData.length = 0
  initDeviceList()
}
const handleDialogValue = (row) => {
  selectParameName.value = row.ParameName
  inputPassDialogVisible.value = true
}
const SetValue = () => {
  inputNewValueDialogVisible.value = true
}
</script>
<style lang="scss" scoped src="@/styles/elementReset.scss"></style>
<style lang="scss" scoped src="./assets/index.scss"></style>
