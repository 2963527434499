<template>
  <el-dialog
    :model-value="dialogVisible"
    title="请输入新的值"
    width="300px"
    @close="handleClose"
    style="border-radius: 10px"
    draggable
  >
    <el-input v-model="newValue" />
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { ElMessage } from 'element-plus'
import { SetParamesValue } from '@/api/DataRecord'
const props = defineProps({
  deviceID: {
    type: String,
    default: ''
  },
  parameName: {
    type: String,
    default: ''
  }
})
const newValue = ref('')
const dialogVisible = ref(false)

const emits = defineEmits(['update:modelValue'])
const handleClose = () => {
  emits('update:modelValue', false)
}
const handleConfirm = () => {
  let info = [
    {
      deviceID: props.deviceID,
      parameName: props.parameName,
      value: newValue.value
    }
  ]
  SetParamesValue({ info: JSON.stringify(info) })
    .then((res) => {
      ElMessage({
        showClose: true,
        type: 'success',
        message: res.Msg,
        duration: 1000
      })
      handleClose()
      emits('SetValue', props.parameName)
    })
    .catch((err) => {
      ElMessage({
        message: `操作失败:${err}`,
        type: 'error'
      })
      handleClose()
    })
}
</script>
